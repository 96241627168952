<template>
  <pageWithSectionsHeader
    @close="hideSegments"
    :sectionsColor="[currentSegmentBg, currentSegmentBg, currentSegmentBg]"
    class="segment-recomendation"
    :closeBtnClass="'btn _gray w-100'"
    :headerTab="headerTab"
    :currentTab="currentHeaderTab"
    @updateTab="updateTab"
  >
    <template v-slot:header1>
      <div class="recomendation-block__index">
        <span class="base-segment__number">{{ currentIndex }}</span>
        <img
          class="base-segment__smile"
          :src="
            require('@/assets/images/segments-smiles/' +
              currentSegment.smileAnimation)
          "
          alt=":)"
        />
      </div>
      <div class="recomendation-block__name">{{ currentSegment.name }}</div>
    </template>
    <template v-slot:header2>
      <p class="si-tit">Статистика по сегменту*</p>
      <p v-for="param in statisticSemgnet" v-html="param" :key="param"></p>

      <p class="si-ps">*На сейчас</p>
    </template>
    <template v-slot:header3>
      <p class="si-tit">Параметры сегмента*</p>
      <p v-html="paramsSemgnet.param1"></p>

      <p v-html="paramsSemgnet.param2"></p>

      <p class="si-ps">*Рассчитывается автоматически при помощи алгоритма ИИ</p>
    </template>
    <template v-slot:btns>
      <button
        @click="recomednationPageClientsShow = !recomednationPageClientsShow"
        :class="recomednationPageClientsShow ? '_blue' : '_green'"
        class="btn"
        v-if="currentSegment.total_clients"
      >
        Показать
        {{ recomednationPageClientsShow ? "рекомендации" : "клиентов" }}
      </button>
      <button
        class="btn"
        style="border-top-left-radius: 0"
        @click="goReactivation"
        v-if="currentSegment.total_clients"
      >
        Реактивация
      </button>
      <!-- @click="goToCreateReactivationWithoutNots" -->
      <a
        class="btn _green"
        :href="downloadUrl"
        v-if="
          recomednationPageClientsShow && downloadUrl && !isCurrentTarifTest
        "
      >
        Скачать клиентов (.xlsx)
      </a>
      <button
        class="btn _green"
        @click="downloadExel"
        v-else-if="recomednationPageClientsShow && isCurrentTarifTest"
      >
        Скачать клиентов (.xlsx)
      </button>

      <button
        class="btn _blue1"
        @click="goToClientsInYclients"
        v-if="recomednationPageClientsShow && false"
      >
        Клиенты сегмента в Yclients
      </button>
    </template>
    <template v-slot:content>
      <iframe
        v-show="!recomednationPageClientsShow"
        ref="iframeRef"
        :src="`https://b24-7zji69.bitrix24site.ru/${currentSegment.key}/`"
        class="segment-recomendation__iframe"
        frameborder="0"
      ></iframe>
      <segmentClients
        v-if="recomednationPageClientsShow"
        class="segment-recomendation__clients"
      ></segmentClients>
    </template>
  </pageWithSectionsHeader>
</template>

<script lang="ts" setup>
import { TypeSegment } from "@/store/modules/segments/segmentsModuleTypes";
import segmentClients from "@/components/main-page/segments/segmentClients.vue";
import { computed, onMounted, onUnmounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import router from "@/router";

import pageWithSectionsHeader from "@/components/UI/bloks/pageWithSectionsHeader.vue";

import goToSegmentInYclients from "@/functions/goToSegmentInYclients";

type typeProps = {
  segment: TypeSegment;
};
/* eslint-disable-next-line */
const props = defineProps<typeProps>();
const store = useStore();

const currentSegment = computed(
  () => store.state.segments.currentSelectSegment
);

const company_rfm = computed(() => store.state.segments.company_rfm);

const modifySegments = computed(() => store.state.segments.modifySegments);

const isCurrentTarifTest = computed(
  () => store.state.tarif.currentTarif.test_tariff
);

const paramsSemgnet = computed(() => {
  const reslt = {
    param1: null,
    param2: null,
  };
  if (!itsSegmentWithoutOrders.value) {
    if (recencyStr.value) {
      reslt.param1 = createParamsStr("Давность визитов", recencyStr.value);
    }
    if (visitsCountStr.value) {
      reslt.param2 = createParamsStr(
        `Количество визитов,`,
        visitsCountStr.value
      );
    }
  }

  return reslt;
});

const itsSegmentWithoutOrders = computed(() => [0, 15,16].includes(currentSegment.value.index))

const without_orders_tab = [
  {
    text: "Все клиенты без визитов",
    id: 0,
    segName: 'without_orders'
  },
  {
    text: "Были записаны, но не пришли",
    id: 1,
    segName: 'without_visits'
  },
  {
    text: "Ни одной записи",
    id: 2,
    segName: 'without_taking_orders'
  },
]

const headerTab = computed(() => {
  if (itsSegmentWithoutOrders.value) {
    return without_orders_tab
  } else {
    return null
  }
});

const currentHeaderTab = computed({
  get() {
    if (currentSegment.value.index === 0) {
      return 0
    } else if (currentSegment.value.index === 16) {
      return 1
    } else if (currentSegment.value.index === 15) {
      return 2
    }

    return 0
  },
});

function updateTab(id) {
  recomednationPageClientsShow.value = false

  if (itsSegmentWithoutOrders.value) {
    const currentTab = without_orders_tab[id]
    const currentSegments = modifySegments.value[currentTab.segName]
    store.state.segments.currentSettingSegment = currentSegments
    store.state.segments.currentSelectSegment = currentSegments
  }
}

function createParamsStr(string: string, value: string | number): string {
  return `${string} - <span class="fw-500">${value}</span>`;
}

const statisticSemgnet = computed(() => {
  const reslt = [];

  if (totalClientsStr.value) {
    reslt.push(createParamsStr("Клиентов", totalClientsStr.value));
  }
  if (totalClientsPercentStr.value) {
    reslt.push(
      createParamsStr("От общего числа", totalClientsPercentStr.value)
    );
  }
  if (avarageCheckStr.value) {
    reslt.push(createParamsStr("Средний чек", avarageCheckStr.value));
  }

  return reslt;
});

const currentIndex = computed(() => {
  const index = currentSegment.value.index;
  if (typeof index === "number") {
    if (index < 10) {
      return "0" + index;
    } else {
      return index;
    }
  } else {
    return index;
  }
});

function goToClientsInYclients() {
  const recenciesObj = store.getters["segments/getRecencies"];
  const url = goToSegmentInYclients(
    store.state.salon_id,
    props.segment,
    recenciesObj
  );

  console.log(url);
}

function downloadExel() {
  store.commit("tarif/setTariffBanner", true);
  router.push("/tariffs");
}
function goToCreateReactivationWithoutNots() {
  store.state.reactivation.reactivationCreateStep = 2;
  store.state.reactivation.isReactivationIncludeNotification = false;
  goReactivation();
}

const recomednationPageClientsShow = computed({
  get: () => store.state.segments.clients.recomednationPageClientsShow,
  set: (newVal) =>
    (store.state.segments.clients.recomednationPageClientsShow = newVal),
});

const total_clients = computed(() => +props.segment.total_clients);

const totalClientsStr = computed(() => total_clients.value.toLocaleString());
const totalClientsPercentStr = computed(
  () => currentSegment.value.total_clients_percent + "%"
);

const currentSegmentBg = computed(() => currentSegment.value.background_color);
const avarageCheckStr = computed(() => {
  const result = Math.trunc(currentSegment.value.average_check);

  if (isNaN(result)) {
    return null;
  } else {
    return result.toLocaleString() + " ₽";
  }
});
const visitsCountStr = computed(() => currentSegment.value.visits || null);

function hideSegments() {
  store.commit("segments/clients/clearClientPages");
  store.commit("segments/setCurrentSegment", { current: null, add: null });
}

const downloadUrl = computed(() => {
  const salonId = store.state.salon_id;
  const segmentId = currentSegment.value.index;

  const concatenatedString = `${salonId}${segmentId}`;
  const base64Encoded = btoa(concatenatedString);

  console.log(salonId, segmentId, base64Encoded);

  const url = `https://rfm.botzavod.com/api/export-company-segment-clients?salon_id=${salonId}&segment_id=${segmentId}&key=${base64Encoded}`;

  return url;
});

function getRecency(recency) {
  const number = +recency;

  let result = 0;
  if (number === 0) {
    return result;
  }
  if (number === 51.5) {
    result = +company_rfm.value.r1_level;
  }

  if (number === 90) {
    result = +company_rfm.value.r2_level;
  }
  if (number === 125) {
    result = +company_rfm.value.r3_level;
  }
  if (number === 160) {
    result = +company_rfm.value.r4_level;
  }

  return result.toFixed();
}

const recencyStr = computed(() => {
  return `от ${getRecency(currentSegment.value.min_recency)} до
          ${getRecency(currentSegment.value.max_recency)} дней`;
});

function goReactivation() {
  isGoingReactivation.value = true;

  router.push("/reactivation");
  window.scrollTo(0, 0);
}
const isGoingReactivation = ref(false);

onUnmounted(() => {
  store.state.segments.clients.recomednationPageClientsShow = false;
  if (isGoingReactivation.value) {
    store.state.segments.showSegmentSettings = true;
  } else {
    store.state.segments.showSegmentSettings = false;
  }
});
</script>

<style lang="scss" scoped>
.segment-recomendation {
  &__iframe {
    grid-row: 2;
    grid-column: 1 / 3;
    width: 100%;
    height: 400vh;
  }
  &__clients {
    grid-row: 2;
    grid-column: 1 / 3;
    margin-top: 35px;
  }

  &__info {
    display: inline-flex;
    gap: 7px;
    border: solid 1px #cccccc;
    padding: 5px;
    border-radius: 19px;
    justify-self: start;
  }
}
.recomendation-block {
  &__index {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  }

  &__name {
    max-width: 165px;
  }
}
</style>
