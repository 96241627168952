const r1_level = 51.5;
const r2_level = 90;
const r3_level = 125;
const r4_level = 160;

const firstVertikalPos = (5 / 3) * 1;
const secondVertikalPos = (5 / 3) * 2;

export default {
  defaultDymanic: [
    {
      key: "at_risk",
      min_recency: r3_level,
      max_recency: r4_level,
      recency: ['r3', 'r4'],
      min_frequency: secondVertikalPos,
      max_frequency: 5,
      background_color: "rgba(93, 75, 207, 0.42)",
      visits: "от 4х и более визитов",
      smileLogo: "smile9.svg",
      smileAnimation: "animation9.webp",
    },
    {
      key: "on_the_brink",
      min_recency: r2_level,
      max_recency: r4_level,
      recency: ['r2', 'r4'],
      min_frequency: firstVertikalPos,
      max_frequency: secondVertikalPos,
      background_color: "#B3C6EB",
      visits: "2 или 3 визита",
      smileLogo: "smile8.svg",
      smileAnimation: "animation8.webp",
    },
    {
      key: "sleeping",
      min_recency: r3_level,
      max_recency: r4_level,
      recency: ['r3', 'r4'],
      min_frequency: 0,
      max_frequency: firstVertikalPos,
      background_color: "rgba(92, 147, 198, 0.69)",
      visits: "1 визит",
      smileLogo: "smile7.svg",
      smileAnimation: "animation7.webp",
    },
    {
      key: "need_attention",
      min_recency: r2_level,
      max_recency: r3_level,
      recency: ['r2', 'r3'],
      min_frequency: 0,
      max_frequency: firstVertikalPos,
      background_color: "rgba(146, 183, 217, 0.6)",
      visits: "1 визит",
      smileLogo: "smile6.svg",
      smileAnimation: "animation6.webp",
    },
    {
      key: "loyal",
      min_recency: r1_level,
      max_recency: r3_level,
      recency: ['r1', 'r3'],
      min_frequency: secondVertikalPos,
      max_frequency: 5,
      background_color: "rgba(88, 111, 230, 0.23)",
      visits: "от 4х более визитов",
      smileLogo: "smile5.svg",
      smileAnimation: "animation5.webp",
    },
    {
      key: "doubting",
      min_recency: r1_level,
      max_recency: r2_level,
      recency: ['r1', 'r2'],
      min_frequency: 0,
      max_frequency: secondVertikalPos,
      background_color: "#CDF3FF",
      visits: "от 1 до 3х визитов",
      smileLogo: "smile4.svg",
      smileAnimation: "animation4.webp",
    },
    {
      key: "champions",
      min_recency: 0,
      max_recency: r1_level,
      recency: [0, 'r1'],
      min_frequency: secondVertikalPos,
      max_frequency: 5,
      background_color: "rgba(255, 170, 120, 0.77)",
      visits: "от 4х и более визитов",
      smileLogo: "smile3.svg",
      smileAnimation: "animation3.webp",
    },
    {
      key: "growing",
      min_recency: 0,
      max_recency: r1_level,
      recency: [0, 'r1'],
      min_frequency: firstVertikalPos,
      max_frequency: secondVertikalPos,
      background_color: "rgba(255, 197, 162, 0.66)",
      visits: "2 или 3 визита",
      smileLogo: "smile2.svg",
      smileAnimation: "animation2.webp",
    },
    {
      key: "newcomer",
      min_recency: 0,
      max_recency: r1_level,
      recency: [0, 'r1'],
      min_frequency: 0,
      max_frequency: firstVertikalPos,
      background_color: "rgba(255, 216, 193, 0.55)",
      visits: "1 визит",
      smileLogo: "smile1.svg",
      smileAnimation: "animation1.webp",
    },
  ],
  defaultAwaiting: [
    {
      min_frequency: secondVertikalPos,
      max_frequency: 5,
      background_color: "rgba(160, 230, 215, 0.88)",
      key: "expect_champion",
      smileLogo: "awaitingSmile.svg",
      smileAnimation: "awaitingSmileAnimation.webp",
    },
    {
      min_frequency: firstVertikalPos,
      max_frequency: secondVertikalPos,
      background_color: "rgba(160, 230, 215, 0.66)",
      key: "expect_growing",
      smileLogo: "awaitingSmile.svg",
      smileAnimation: "awaitingSmileAnimation.webp",
    },
    {
      min_frequency: 0,
      max_frequency: firstVertikalPos,
      background_color: "rgba(160, 230, 215, 0.42)",
      key: "expect_newcomer",
      smileLogo: "awaitingSmile.svg",
      smileAnimation: "awaitingSmileAnimation.webp",
    },
  ],
  outflow: {
    background_color: "rgba(12, 86, 139, 0.51)",
    smileLogo: "smile10.svg",
    smileAnimation: "animation10.webp",
  },
  without_orders: {
    background_color: "rgba(254, 228, 171, 0.59)",
    border_color: '#FEE4AB',
    index: 0,
    name: "Клиенты без визитов",
    total_clients: 0,
    total_clients_percent: 0,
    key: "havent_visit",
    smileLogo: "smile0.svg",
    smileAnimation: "animation0.webp",
  },
  without_visits: {
    background_color: "rgb(235 210 154 / 59%);",
    border_color: '#FEE4AB',
    index: 16,
    name: "Были записаны, но не пришли",
    key: "havent_visit",
    smileLogo: "smile0.svg",
    smileAnimation: "animation0.webp",
  },
  without_taking_orders: {
    background_color: "rgb(213 191 143 / 59%)",
    border_color: '#FEE4AB',
    index: 15,
    name: "Ни одной записи",
    key: "havent_visit",
    smileLogo: "smile0.svg",
    smileAnimation: "animation0.webp",
  },
  had_come: {
    index: 123,
    min_recency: 160,
    max_recency: null,
    min_frequency: 0,
    max_frequency: null,
    key: "had_visits",
    name: "Клиент пришел",
    background_color: "rgba(255, 173, 123, 0.88)",
    border_color: "#ffbe97",
    smileLogo: "smile11.svg",
    smileAnimation: "animation11.webp",
  },
  awaitSegment: {
    key: "awaiting_segments",
    min_recency: 0,
    max_recency: 40,
    min_frequency: 0,
    max_frequency: 1.6666666666666667,
    background_color: "#9CF6E275",
    border_color: "#abe9dc",
    index: 121314,
    total_clients: "x",
    average_check: "x",
    name: "Зона ожидания",
    smileLogo: "awaitingSmile.svg",
    smileAnimation: "awaitingSmileAnimation.webp",
  },
  black_list: {
    key: "black_list",
    background_color: "rgba(41, 45, 50, 0.76)",
    index: 12,
    text_color: '#fff',
    total_clients: "x",
    total_clients_percent: 'x',
    name: "Черный список",
    smileLogo: "smileBlackList.svg",
    smileAnimation: "smileBlackListAnimation.webp",
  }
};
