import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "page-sections-header__top" }
const _hoisted_2 = {
  key: 0,
  class: "sections-header__tab"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "sections-info" }
const _hoisted_5 = { class: "page-sections-header__btns" }
const _hoisted_6 = { class: "page-sections-header__content" }



type typeTabItem = {
  text: string;
  id: number;
};
type typeProps = {
  sectionsColor: Array<string>;
  closeBtnClass: string;
  headerTab?: Array<typeTabItem>;
  currentTab?: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'pageWithSectionsHeader',
  props: {
    sectionsColor: { default: () => ["#fff", "#fff", "#fff"] },
    closeBtnClass: {},
    headerTab: {},
    currentTab: {}
  },
  emits: ["updateTab"],
  setup(__props: any, { emit: __emit }) {

const emit = __emit;

function handleTab(id) {
  emit("updateTab", id);
}

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "page-sections-header",
    style: _normalizeStyle('--page-sections-bg: ' + _ctx.sectionsColor[0] + '')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "top"),
      _createElementVNode("button", {
        class: _normalizeClass(_ctx.closeBtnClass),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, " Закрыть без изменений ", 2)
    ]),
    _createElementVNode("header", {
      class: _normalizeClass(["sections-header", { '_with-tab': _ctx.headerTab }])
    }, [
      (_ctx.headerTab)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headerTab, (item, index) => {
              return (_openBlock(), _createElementBlock("button", {
                key: index,
                onClick: ($event: any) => (handleTab(item.id)),
                class: _normalizeClass({ active: _ctx.currentTab === item.id })
              }, _toDisplayString(item.text), 11, _hoisted_3))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "sections-info__block",
          style: _normalizeStyle(`background-color:${props.sectionsColor[0]}`)
        }, [
          _renderSlot(_ctx.$slots, "header1")
        ], 4),
        _createElementVNode("div", {
          class: "sections-info__block",
          style: _normalizeStyle(`background-color:${props.sectionsColor[1]}`)
        }, [
          _renderSlot(_ctx.$slots, "header2")
        ], 4),
        _createElementVNode("div", {
          class: "sections-info__block",
          style: _normalizeStyle(`background-color:${props.sectionsColor[2]}`)
        }, [
          _renderSlot(_ctx.$slots, "header3")
        ], 4)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "btns")
    ]),
    _createElementVNode("div", _hoisted_6, [
      _renderSlot(_ctx.$slots, "content")
    ])
  ], 4))
}
}

})